<template>
  <div class="relative flex-1">
    <div class="w-full bg-blue-400 text-white p-2 px-4 font-medium text-lg">
      <span class="font-bold">{{registration($store.getters['tradein/versions'][0])}}</span> {{title($store.getters['tradein/versions'][0])}}<br/>{{subTitle($store.getters['tradein/versions'][0])}}
    </div>
    <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm">
      <div v-for="version in $store.getters['tradein/versions']" :key="version.typeId" class="w-1/2">
        <div @click="select(version)" class="ml-2 mt-2 px-4 py-2 rounded-lg border-2 bg-white border-gray-100 font-bold text-gray-600 cursor-pointer hover:bg-blue-100 hover:border-blue-600 hover:tet-blue-700">
          <div class="font-bold"><span class="font-medium">{{model(version)}}</span> {{version.typeText.replace('##/fr/', '')}}</div>
          <div class="flex flex-row font-normal text-center border-t border-gray-100 mt-1 pt-1">
            <div class="w-1/6 px-2 border-r border-gray-100 truncate">{{version.doorCount}} drs.</div>
            <div class="w-1/6 px-2 border-r border-gray-100 truncate">{{version.seatCount}} pl.</div>
            <div class="w-1/5 px-2 border-r border-gray-100 truncate">{{height(version)}}</div>
            <div class="w-1/4 px-2 border-r border-gray-100 truncate">{{version.transmissionText}}</div>
            <div class="w-1/5 px-2 truncate">{{version.bodyTypeText}}</div>
          </div>
          <div class="flex flex-row font-normal text-center border-t border-gray-100 mt-1 pt-1">
            <div class="w-full px-2">{{available(version)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {renderDateRange} from "@/common";

export default {
  name: "Version",
  methods: {
    select: function (version) {
      this.$store.dispatch("seller/selectVersion", version);
    },
    registration: function(version) {
      return this.moment(version.firstRegistrationDate).format('L');
    },
    title: function(version) {
      return version.originDescription.replace('##/fr/', '');
    },
    subTitle: function(version) {
      return version.originColorExterior ? version.originColorExterior.replace('##/fr/', '') : "";
    },
    model: function(version) {
      return version.modelGroupText!==undefined&&version.modelGroupText!==null ?  version.modelGroupText : '';
    },
    height: function(version) {
      return (version.height!==undefined&&version.height!==null&&version.height!==0) ? version.height + " mm" : '';
    },
    available: function(version) {
      return "Available from" + renderDateRange(version.start,'') + " " + renderDateRange(version.stop,"until")
    }
  },
}
</script>

<style scoped>

</style>