<template>
  <div class="fixed inset-0 overflow-hidden bg-gray-400 bg-opacity-50" style="z-index: 998;" >
    <modal :modal="modal"></modal>
    <div class="absolute inset-0 overflow-hidden">
      <section :class="[ centered ? 'max-w-full justify-center flex' : 'absolute inset-y-0 right-0 pl-10 max-w-full flex']">
        <div class="w-screen max-w-5xl">
          <div class="h-screen flex flex-col bg-white shadow-xl overflow-hidden">
            <header class="space-y-1 py-2 px-2 bg-blue-700">
              <div class="flex items-center justify-between">
                <div class="text-white flex flex-row">
                  <div v-if="$store.getters['tradein/refreshable']" v-on:click="refresh" class="hover:bg-white hover:text-blue-800 px-2 py-1 border border-blue-300 rounded font-bold flex flex-col justify-center text-md text-blue-300 cursor-pointer">
                    <i class="fa fa-sync-alt"></i>
                  </div>
                  <div v-if="$store.getters['tradein/part']=='vehicle-detail'" v-on:click="save" class="ml-4 hover:bg-white hover:text-blue-800 px-6 py-1 border border-white rounded font-bold flex flex-col justify-center text-md cursor-pointer">
                    Save
                  </div>
                  <div v-if="$store.getters['tradein/part']!=='vehicle-detail'&&$store.getters['tradein/part']!=='start'" v-on:click="start" class="ml-4 hover:bg-white hover:text-blue-800 px-6 py-1 border border-white rounded font-bold flex flex-col justify-center text-md cursor-pointer">
                    Back
                  </div>
                  <div v-if="$store.getters['seller/header'].secondaryActionTitle" class="group ml-4 pl-6 py-1 border border-white rounded font-bold text-md cursor-pointer hover:bg-white hover:text-blue-800 flex flex-row">
                    <div class="mr-6">{{$store.getters['seller/header'].secondaryActionTitle}}</div>
                    <div class="px-2 border-l border-white group-hover:border-blue-600"><i class="fa fa-angle-down"></i></div>
                  </div>
                  <div  v-if="$store.getters['seller/header'].customerPrimary" @click="goToCustomer" class="py-1 ml-4 leading-tight flex flex-col justify-center bg-blue-50 px-2 text-blue-700 rounded cursor-pointer" style="min-width: 200px;">
                    <span class="font-bold">{{$store.getters['seller/header'].customerPrimary}}</span>
                    {{$store.getters['seller/header'].customerSecondary}}&nbsp;
                  </div>
                  <div class="ml-4 px-6 py-1 border border-blue-500 bg-blue-500 rounded font-bold flex flex-col justify-center text-md">{{$store.getters['seller/header'].state}}</div>
                  <div class="ml-4 leading-tight flex flex-col justify-center">
                    <span class="font-bold">{{$store.getters['seller/header'].title}}</span>
                    {{$store.getters['seller/header'].description}}
                  </div>
                </div>
                <div class="h-6 flex items-center">
                  <div class="text-white text-md mr-6">
                    <div class="font-bold">{{$store.getters['seller/header'].reference}}</div>
                  </div>
                  <button aria-label="Close panel" class="text-blue-100 hover:text-white transition ease-in-out duration-150 px-1 mr-2" @click="toggleCenter">
                    <i class="fas fa-arrows-alt-h"></i>
                  </button>
                  <button aria-label="Close panel" class="text-blue-100 hover:text-white transition ease-in-out duration-150" @click="close">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </header>
            <div v-if="$store.getters['seller/loading']" class="flex flex-col h-full">
              <loading></loading>
            </div>
            <div v-else class="flex flex-col h-full overflow-y-scroll">
              <trade-in-start v-if="$store.getters['tradein/part']=='start'"></trade-in-start>
              <vin-not-found v-if="$store.getters['tradein/part']=='vin-not-found'" ></vin-not-found>
              <select-version v-if="$store.getters['tradein/part']=='select-version'"></select-version>
              <select-make v-if="$store.getters['tradein/part']=='select-make'"></select-make>
              <select-registration v-if="$store.getters['tradein/part']=='select-registration'"></select-registration>
              <select-model v-if="$store.getters['tradein/part']=='select-model'"></select-model>
              <select-specs v-if="$store.getters['tradein/part']=='select-specs'"></select-specs>
              <select-versions v-if="$store.getters['tradein/part']=='select-versions'"></select-versions>
              <select-customer v-if="$store.getters['tradein/part']=='select-customer'"></select-customer>
              <vehicle-edit v-if="$store.getters['tradein/part']=='vehicle-detail'"></vehicle-edit>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import loading from "@/components/loading";
import tradeInStart from "./Start"
import notFound from "./Error"
import selectVersion from "./Version"
import vehicleEdit from "./edit/Edit"
import makes from "./eurotax/Make"
import registration from "./eurotax/Registration"
import model from "./eurotax/Model"
import specs from "./eurotax/Specs"
import versions from "./eurotax/Versions"
import customer from "./search/Customer"
import {modalInfo} from "@/common/modal";
import modal from "@/components/modal";


export default {
  name: "Detail",
  components : {
    'loading': loading,
    'trade-in-start': tradeInStart,
    'vin-not-found': notFound,
    'select-version': selectVersion,
    'vehicle-edit': vehicleEdit,
    'select-make': makes,
    'select-registration': registration,
    'select-model': model,
    'select-specs': specs,
    'select-versions': versions,
    'select-customer': customer,
    'modal': modal
  },
  data() {
    return {
      centered: true,
      detailMenu: null,
      pictures: [],
      modal: modalInfo()
    }
  },
  methods: {
    toggleCenter() {
      this.centered = !this.centered;
    },
    goToCustomer() {

    },
    refresh() {
      this.modal.color = 'orange';
      this.modal.view('Trade In', 'Are you sure to reload the file and eventually loose data ?', 'Reload', 'exclamation-triangle', () => {
        this.$store.commit('seller/openItem', this.$store.getters['seller/lead'].leadId);
      })
    },
    save() {
    },
    start() {
      this.$store.commit('tradein/setPart', 'start');
    },
    close() {
      this.$store.commit("master/closeDetail");
    }
  }
}
</script>

<style scoped>

</style>